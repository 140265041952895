var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{attrs:{"accent-color":"primary"}},[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cil-graph"}}),_vm._v(" Sản phẩm & hiệu quả bán hàng ")],1),(_vm.timeBox)?[_vm._v(" ("+_vm._s(_vm.$moment(_vm.timeBox.fromDate) .local() .format("DD/MM/YYYY"))+" - "+_vm._s(_vm.$moment(_vm.timeBox.toDate) .local() .format("DD/MM/YYYY"))+") ")]:_vm._e(),_c('div',{staticClass:"card-header-actions"},[_c('CLink',{staticClass:"card-header-action",on:{"click":function($event){_vm.showingRange = true}}},[_c('CIcon',{attrs:{"name":"cil-calendar","custom-classes":"c-icon text-dark"}})],1),_c('CLink',{staticClass:"card-header-action btn-minimize",on:{"click":function($event){_vm.isCollapsed = !_vm.isCollapsed}}},[_c('CIcon',{attrs:{"name":("cil-chevron-" + (_vm.isCollapsed ? 'bottom' : 'top'))}})],1)],1)],2),_c('CCollapse',{attrs:{"show":!_vm.isCollapsed,"duration":400}},[_c('CCardBody',[(_vm.loading)?_c('div',[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang tải... ")],1):_vm._e(),_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","size":"sm","striped":"","no-sorting":"","sorter":"","sorterValue":{ column: 'orderCount', asc: false }},scopedSlots:_vm._u([{key:"productId",fn:function(ref){
var index = ref.index;
return _c('td',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(index + 1)+".")])])}},{key:"giftCount",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(item.giftCount))+" ")])}},{key:"orderCount",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(item.orderCount))+" ")])}},{key:"orderCountRate",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(item.orderCountRate.toFixed(2)))+"% ")])}},{key:"orderTotal",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(item.orderTotal))+" ")])}},{key:"orderTotalRate",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(item.orderTotalRate.toFixed(2)))+"% ")])}}])},[_c('template',{slot:"footer"},[_c('tfoot',{staticClass:"font-weight-bold"},[_c('tr',[_c('td'),_c('td',[_vm._v("TỔNG")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas( _vm.tableItems.reduce(function (acc, cur) { return acc + cur.giftCount; }, 0) ))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas( _vm.tableItems.reduce(function (acc, cur) { return acc + cur.orderCount; }, 0) ))+" ")]),_c('td'),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas( _vm.tableItems.reduce(function (acc, cur) { return acc + cur.orderTotal; }, 0) ))+" ")]),_c('td')])])])],2)],1)],1)],1),_c('date-range',{attrs:{"showing":_vm.showingRange,"timeBox":_vm.timeBox},on:{"update:showing":function($event){_vm.showingRange=$event},"update:timeBox":function($event){_vm.timeBox=$event},"update:time-box":function($event){_vm.timeBox=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }