<template>
  <div>
    <TotalNIncome />
    <AmountByAgencyMonth />
    <ProductEffective />
    <ProvinceEffective v-if="isAdmin" />
  </div>
</template>

<script>
import TotalNIncome from "./TotalNIncome.vue";
import AmountByAgencyMonth from "./AmountByAgencyMonth.vue";
import ProductEffective from "./ProductEffective.vue";
import ProvinceEffective from "./ProvinceEffective.vue";

export default {
  name: "Report",
  components: {
    TotalNIncome,
    AmountByAgencyMonth,
    ProductEffective,
    ProvinceEffective,
  },
  computed: {
    isAdmin() {
      let authUser = this.$user.getters.authUser;
      return authUser
        ? authUser && [this.$const.ROLES.SuperAdmin].includes(authUser.role)
        : false;
    },
  },
};
</script>
