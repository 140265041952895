<template>
  <div>
    <CCard accent-color="primary">
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cil-graph" />
          Sản phẩm & hiệu quả bán hàng
        </span>
        <template v-if="timeBox">
          ({{
            $moment(timeBox.fromDate)
              .local()
              .format("DD/MM/YYYY")
          }}
          -
          {{
            $moment(timeBox.toDate)
              .local()
              .format("DD/MM/YYYY")
          }})
        </template>
        <div class="card-header-actions">
          <CLink class="card-header-action" @click="showingRange = true">
            <CIcon name="cil-calendar" custom-classes="c-icon text-dark" />
          </CLink>
          <CLink
            class="card-header-action btn-minimize"
            @click="isCollapsed = !isCollapsed"
          >
            <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" />
          </CLink>
        </div>
      </CCardHeader>
      <CCollapse :show="!isCollapsed" :duration="400">
        <CCardBody>
          <div v-if="loading">
            <CSpinner color="info" size="sm" class="ml-2" />
            Đang tải...
          </div>

          <CDataTable
            class="mb-0 table-outline"
            hover
            :items="tableItems"
            :fields="tableFields"
            head-color="light"
            size="sm"
            striped
            no-sorting
            sorter
            :sorterValue="{ column: 'orderCount', asc: false }"
          >
            <td slot="productId" class="text-right" slot-scope="{ index }">
              <span>{{ index + 1 }}.</span>
            </td>
            <td slot="giftCount" slot-scope="{ item }" class="text-right">
              {{ $func.addCommas(item.giftCount) }}
            </td>
            <td slot="orderCount" slot-scope="{ item }" class="text-right">
              {{ $func.addCommas(item.orderCount) }}
            </td>
            <td slot="orderCountRate" slot-scope="{ item }" class="text-right">
              {{ $func.addCommas(item.orderCountRate.toFixed(2)) }}%
            </td>
            <td slot="orderTotal" slot-scope="{ item }" class="text-right">
              {{ $func.addCommas(item.orderTotal) }}
            </td>
            <td slot="orderTotalRate" slot-scope="{ item }" class="text-right">
              {{ $func.addCommas(item.orderTotalRate.toFixed(2)) }}%
            </td>
            <template slot="footer">
              <tfoot class="font-weight-bold">
                <tr>
                  <td></td>
                  <td>TỔNG</td>
                  <td class="text-right">
                    {{
                      $func.addCommas(
                        tableItems.reduce((acc, cur) => acc + cur.giftCount, 0)
                      )
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      $func.addCommas(
                        tableItems.reduce((acc, cur) => acc + cur.orderCount, 0)
                      )
                    }}
                  </td>
                  <td></td>
                  <td class="text-right">
                    {{
                      $func.addCommas(
                        tableItems.reduce((acc, cur) => acc + cur.orderTotal, 0)
                      )
                    }}
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </template>
          </CDataTable>
        </CCardBody>
      </CCollapse>
    </CCard>
    <date-range :showing.sync="showingRange" :timeBox.sync="timeBox" />
  </div>
</template>

<script>
import DateRange from "@/components/DateRange.vue";

export default {
  components: { DateRange },
  data() {
    return {
      showingRange: false,
      timeBox: {
        //label: `Tháng này`,
        fromDate: this.$moment()
          .startOf("month")
          .toISOString(),
        toDate: this.$moment(new Date())
          .endOf("day")
          .toISOString(),
      },
      loading: false,
      tableItems: [],
      isCollapsed: true,
    };
  },
  computed: {
    tableFields() {
      return [
        { key: "productId", label: "STT", sorter: false },
        { key: "productName", label: "Tên Sản Phẩm" },
        { key: "giftCount", label: "Số quà tặng", _classes: "text-right" },
        { key: "orderCount", label: "Số đơn hàng", _classes: "text-right" },
        {
          key: "orderCountRate",
          label: "Tỉ lệ đơn hàng",
          _classes: "text-right",
        },
        {
          key: "orderTotal",
          label: "Doanh số bán hàng",
          _classes: "text-right",
        },
        {
          key: "orderTotalRate",
          label: "Tỉ lệ doanh số",
          _classes: "text-right",
        },
      ];
    },
  },
  mounted() {
    // this.loadReport(this.timeBox);
  },
  watch: {
    timeBox(val) {
      if (val && !this.isCollapsed) {
        this.loadReport(val);
      }
    },
    isCollapsed(val) {
      if (!val && this.timeBox) {
        this.loadReport(this.timeBox);
      }
    },
  },
  methods: {
    async loadReport(timeBox) {
      this.loading = true;
      try {
        this.tableItems = await this.loadProductEffective(timeBox);
      } catch (error) {
        //
      }
      this.loading = false;
    },

    async loadProductEffective(timeBox) {
      let resp = await this.$http.get(`Report/ProductEffective`, {
        params: {
          fromDate: timeBox.fromDate,
          toDate: timeBox.toDate,
        },
      });
      if (resp && resp.status == 200 && resp.data && resp.data.length) {
        let orderCountSum = resp.data.reduce(
          (acc, cur) => acc + cur.orderCount,
          0
        );
        let orderTotalSum = resp.data.reduce(
          (acc, cur) => acc + cur.orderTotal,
          0
        );
        return resp.data.map((_) => {
          return {
            productId: _.productId,
            productName: _.productName,
            giftCount: _.giftCount,
            orderCount: _.orderCount,
            orderCountRate: (_.orderCount / orderCountSum) * 100,
            orderTotal: _.orderTotal,
            orderTotalRate: (_.orderTotal / orderTotalSum) * 100,
          };
        });
      }
      return [];
    },
  },
};
</script>
