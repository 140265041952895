<template>
  <div>
    <CCard accent-color="primary">
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cil-graph" />
          Đơn hàng & Doanh số theo cửa hàng
        </span>
        <template v-if="timeBox">
          ({{
            $moment(timeBox.fromDate)
              .local()
              .format("DD/MM/YYYY")
          }}
          -
          {{
            $moment(timeBox.toDate)
              .local()
              .format("DD/MM/YYYY")
          }})
        </template>
        <div class="card-header-actions">
          <CLink class="card-header-action" @click="showingRange = true">
            <CIcon name="cil-calendar" custom-classes="c-icon text-dark" />
          </CLink>
          <CLink
            class="card-header-action btn-minimize"
            @click="isCollapsed = !isCollapsed"
          >
            <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" />
          </CLink>
        </div>
      </CCardHeader>
      <CCollapse :show="!isCollapsed" :duration="400">
        <CCardBody>
          <div v-if="loading">
            <CSpinner color="info" size="sm" class="ml-2" />
            Đang tải...
          </div>
          <p class="font-italic text-right">
            <u>Ghi chú:</u> &nbsp;&nbsp;&nbsp; <b>DS</b> - Doanh số. <b>DT</b> -
            Doanh thu. <b>LN</b> - Lợi nhuận. <b>(*)</b> Cùng kỳ tháng trước.
          </p>

          <CDataTable
            class="mb-0 table-outline"
            hover
            :items="tableItems"
            :fields="tableFields"
            head-color="light"
            size="sm"
            striped
            no-sorting
            sorter
            :sorterValue="{ column: 'orderTotal', asc: false }"
          >
            <td slot="agencyId" class="text-right" slot-scope="{ index }">
              <span>{{ index + 1 }}.</span>
            </td>
            <td
              slot="orderCount"
              slot-scope="{ item, index }"
              class="text-right"
            >
              <a
                href="javascript:"
                v-c-popover="{
                  header: 'Số đơn hàng',
                  content: popoverContent(item),
                  active: false,
                  focus: true,
                  placement: index + 5 < tableItems.length ? 'bottom' : 'top',
                }"
              >
                {{ $func.addCommas(item.orderCount) }}
              </a>
            </td>
            <td slot="orderTotal" slot-scope="{ item }" class="text-right">
              {{ $func.addCommas(item.orderTotal) }}
            </td>
            <td slot="incomeOrder" slot-scope="{ item }" class="text-right">
              {{ $func.addCommas(item.incomeOrder) }}
            </td>
            <td slot="incomePlus" slot-scope="{ item }" class="text-right">
              {{ $func.addCommas(item.incomePlus) }}
            </td>
            <td slot="incomeTotal" slot-scope="{ item }" class="text-right">
              {{ $func.addCommas(item.incomeTotal) }}
            </td>
            <td slot="orderTotalPre" slot-scope="{ item }" class="text-right">
              {{ $func.addCommas(item.orderTotalPre) }}
            </td>
            <td
              slot="growRate"
              slot-scope="{ item }"
              class="text-right"
              :class="{
                'text-success': item.growRate != undefined && item.growRate > 0,
                'text-warning': item.growRate != undefined && item.growRate < 0,
                'text-danger': item.growRate != undefined && item.growRate < 0,
              }"
            >
              <span v-if="item.growRate != undefined">
                {{ $func.addCommas(item.growRate.toFixed(2)) }}%
              </span>
            </td>
          </CDataTable>
        </CCardBody>
      </CCollapse>
    </CCard>
    <date-range :showing.sync="showingRange" :timeBox.sync="timeBox" />
  </div>
</template>

<script>
import DateRange from "@/components/DateRange.vue";

export default {
  components: { DateRange },
  data() {
    return {
      showingRange: false,
      timeBox: {
        //label: `Tháng này`,
        fromDate: this.$moment()
          .startOf("month")
          .toISOString(),
        toDate: this.$moment(new Date())
          .endOf("day")
          .toISOString(),
      },
      loading: false,
      tableItems: [],
      isCollapsed: true,
    };
  },
  computed: {
    tableFields() {
      return [
        { key: "agencyId", label: "STT", sorter: false },
        { key: "agencyName", label: "Tên Cửa hàng" },
        { key: "orderCount", label: "Số đơn hàng", _classes: "text-right" },
        {
          key: "orderTotal",
          label: "DS bán hàng",
          _classes: "text-right",
        },
        {
          key: "incomeOrder",
          label: "DT bán hàng",
          _classes: "text-right",
        },
        {
          key: "incomePlus",
          label: "DT góp + chia LN",
          _classes: "text-right",
        },
        { key: "incomeTotal", label: "Tổng DT", _classes: "text-right" },
        {
          key: "orderTotalPre",
          label: "DS bán hàng (*)",
          _classes: "text-right",
        },
        { key: "growRate", label: "Tăng trưởng", _classes: "text-right" },
      ];
    },
  },
  mounted() {
    // this.loadReport(this.timeBox);
  },
  watch: {
    timeBox(val) {
      if (val && !this.isCollapsed) {
        this.loadReport(val);
      }
    },
    isCollapsed(val) {
      if (!val && this.timeBox) {
        this.loadReport(this.timeBox);
      }
    },
  },
  methods: {
    async loadReport(timeBox) {
      this.loading = true;
      try {
        this.tableItems = [];
        this.tableItems = await this.loadAmountByAgencyMonth(timeBox);
      } catch (error) {
        //
      }
      this.loading = false;
    },

    async loadAmountByAgencyMonth(timeBox) {
      let preFromDate = this.getPreDate(timeBox.fromDate);
      let preToDate = this.getPreDate(timeBox.toDate);
      let resp = await this.$http.get(`Report/AmountByAgencyMonth`, {
        params: {
          fromDate: timeBox.fromDate,
          toDate: timeBox.toDate,
          preFromDate: new Date(preFromDate),
          preToDate: new Date(preToDate),
        },
      });
      if (resp && resp.status == 200) {
        return resp.data && resp.data.length
          ? resp.data.map((_) => {
              return {
                agencyId: _.agencyId,
                agencyName: _.agencyName,
                orderCount: _.orderCount,
                orderTotal: _.orderTotal,

                orderCountSell: _.orderCountSell,
                orderTotalSell: _.orderTotalSell,
                orderCountSharing: _.orderCountSharing,
                orderTotalSharing: _.orderTotalSharing,
                orderCountShared: _.orderCountShared,
                orderTotalShared: _.orderTotalShared,

                incomeOrder: _.incomeOrder,
                incomePlus: _.incomePlus,
                incomeTotal: _.incomeTotal,
                orderTotalPre: _.orderTotalPre,
                growRate: _.orderTotalPre
                  ? ((_.orderTotal - _.orderTotalPre) / _.orderTotalPre) * 100
                  : 0,
              };
            })
          : [];
      }
      return [];
    },
    popoverContent(row) {
      return `<table>
        <thead>
          <tr><th>Loại đơn hàng</th><th>SL</th><th>Doanh số</th><tr>
        </thead>
        <tbody>
          <tr><td>Nhận 100%</td>
            <td>${this.$func.addCommas(row.orderCountSell)}</td>
            <td>${this.$func.addCommas(row.orderTotalSell)}</td><tr>
          <tr><td>Bị chia DS</td>
            <td>${this.$func.addCommas(row.orderCountSharing * 2)} x 1/2</td>
            <td>${this.$func.addCommas(row.orderTotalSharing)}</td><tr>
          <tr><td>Được chia DS</td>
            <td>${this.$func.addCommas(row.orderCountShared * 2)} x 1/2</td>
            <td>${this.$func.addCommas(row.orderTotalShared)}</td><tr>
        </tbody>
        <tfoot>
          <tr><th>Tổng</th><th>${this.$func.addCommas(
            row.orderCount
          )}</th><th>${this.$func.addCommas(row.orderTotal)}</th><tr>
        </tfoot>
      </table>`;
    },

    getPreDate(date) {
      let startDOM = this.$moment(date).startOf("month");
      let endDOM = this.$moment(date).endOf("month");
      if (date == startDOM) {
        return this.$moment(date)
          .subtract(1, "months")
          .date(1);
      } else if (date == endDOM) {
        return this.$moment(date)
          .add(2, "months")
          .date(0);
      } else {
        return this.$moment(date).subtract(1, "months");
      }
    },
  },
};
</script>
