<template>
  <CModal
    v-if="showing"
    title="Chọn thời gian"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
  >
    <CRow>
      <CCol md="6">
        <div role="group" class="form-group">
          <label>
            Từ ngày
          </label>
          <datetime
            type="date"
            v-model="from"
            format="dd/MM/yyyy"
            input-class="form-control"
            value-zone="local"
          ></datetime>
        </div>
      </CCol>
      <CCol md="6">
        <div role="group" class="form-group">
          <label>
            Đến ngày
          </label>
          <datetime
            type="date"
            v-model="to"
            format="dd/MM/yyyy"
            input-class="form-control"
            value-zone="local"
          ></datetime>
        </div>
      </CCol>
    </CRow>
    <template v-slot:footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="detailModal = false"
      >
        Hủy
      </button>
      <button type="button" class="btn btn-primary" @click="okHandle">
        Đồng ý
      </button>
    </template>
  </CModal>
</template>

<script>
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

export default {
  props: ["showing", "timeBox"],
  components: { datetime: Datetime },
  data() {
    return {
      detailModal: false,
      from: this.timeBox ? this.timeBox.fromDate : null,
      to: this.timeBox ? this.timeBox.toDate : null,
    };
  },
  watch: {
    timeBox(val) {
      if (val) {
        this.from = val.fromDate;
        this.to = val.toDate;
      }
    },
    showing(val) {
      this.detailModal = val;
    },
    detailModal(val) {
      this.$emit("update:showing", val);
    },
  },
  methods: {
    okHandle() {
      if (this.from && this.to) {
        let timeBox = {
          subLabel: `${this.$moment(this.from).format(
            "DD/MM/YYYY"
          )} -${this.$moment(this.to).format("DD/MM/YYYY")}`,
          fromDate: this.$moment(this.from).toISOString(),
          toDate: this.$moment(this.to)
            .endOf("day")
            .toISOString(),
        };
        this.$emit("update:timeBox", timeBox);
        this.detailModal = false;
      } else {
        alert("Vui lòng chọn ngày!");
      }
    },
  },
};
</script>
