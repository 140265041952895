<template>
  <div>
    <CCard accent-color="primary">
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cil-chart-pie" />
          Báo cáo tổng quan
        </span>
        <template v-if="timeBox">
          ({{
            $moment(timeBox.fromDate)
              .local()
              .format("DD/MM/YYYY")
          }}
          -
          {{
            $moment(timeBox.toDate)
              .local()
              .format("DD/MM/YYYY")
          }})
        </template>
        <div class="card-header-actions">
          <a href="javascript:" @click="showingRange = true">
            <CIcon name="cil-calendar" custom-classes="c-icon text-dark" />
          </a>
        </div>
      </CCardHeader>
      <CCardBody>
        <div v-if="loading">
          <CSpinner color="info" size="sm" class="ml-2" />
          Đang tải...
        </div>
        <CRow>
          <CCol md="5" v-if="isAdmin">
            <strong>Doanh Số & Doanh Thu</strong>
            <CChartBar
              style="height:300px"
              :datasets="totalDatasets"
              :options="totalOptions"
              :labels="totalLabels"
            />
          </CCol>
          <CCol md="7">
            <strong>Doanh Thu (%)</strong>
            <CChartPie
              :datasets="incomeDatasets"
              :labels="incomeLabels"
              :options="incomeOptions"
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <date-range :showing.sync="showingRange" :timeBox.sync="timeBox" />
  </div>
</template>

<script>
import { CChartBar, CChartPie } from "@coreui/vue-chartjs";
import DateRange from "@/components/DateRange.vue";
import { getColor, deepObjectsMerge } from "@coreui/utils/src";

export default {
  components: { DateRange, CChartBar, CChartPie },
  data() {
    return {
      showingRange: false,
      timeBox: {
        //label: `Tháng này`,
        fromDate: this.$moment()
          .startOf("month")
          .toISOString(),
        toDate: this.$moment(new Date())
          .endOf("day")
          .toISOString(),
      },
      loading: false,
      totalNIncomes: [],
      incomes: [],
    };
  },
  mounted() {
    this.loadReport(this.timeBox);
  },
  watch: {
    timeBox(val) {
      this.loadReport(val);
    },
  },
  computed: {
    isAdmin() {
      let authUser = this.$user.getters.authUser;
      return authUser
        ? authUser &&
            [this.$const.ROLES.SuperAdmin, this.$const.ROLES.Owner].includes(
              authUser.role
            )
        : false;
    },

    totalLabels() {
      let totalNIncomes = this.totalNIncomes;
      return totalNIncomes && totalNIncomes.length
        ? totalNIncomes.map((_) => this.$const.AGENCY_TYPES_TEXT[_.agencyType])
        : [];
    },
    totalDatasets() {
      let totalNIncomes = this.totalNIncomes;
      let data = [
        { code: "totalAmount", name: "Doanh số", color: "primary" },
        { code: "incomeAmount", name: "Doanh thu", color: "danger" },
      ].map((_) => {
        return {
          data:
            totalNIncomes && totalNIncomes.length
              ? totalNIncomes.map((t) => t[_.code])
              : [],
          backgroundColor: getColor(_.color),
          pointHoverBackgroundColor: getColor(_.color),
          label: _.name,
          barPercentage: 0.5,
          categoryPercentage: 1,
        };
      });

      return deepObjectsMerge(data, {});
    },
    totalOptions() {
      let data = {
        maintainAspectRatio: false,
        legend: {
          display: true,
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return this.$func.addCommas(tooltipItem.value);
            },
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                callback: (value) => {
                  return this.$func.addCommas(value);
                },
              },
            },
          ],
        },
      };
      return deepObjectsMerge(data, {});
    },

    incomeLabels() {
      let incomes = this.incomes;
      return incomes && incomes.length ? incomes.map((_) => _.sourceName) : [];
    },

    incomeDatasets() {
      let incomes = this.incomes;
      let sum = incomes.reduce(
        (partialSum, a) => partialSum + a.incomeAmount,
        0
      );
      return [
        {
          backgroundColor: [
            "#4dbd74",
            "#20a8d8",
            "#f86c6b",
            "#212529",
            "#ffc107",
            "#0d6efd",
            "#6c757d",
            "#4B0082",
            "#DB7093",
            "#A52A2A",
            "#8B008B",
            "#2F4F4F",
            "#D71868",
          ],
          data:
            incomes && incomes.length
              ? incomes.map((_) => Math.ceil((_.incomeAmount / sum) * 100))
              : [],
        },
      ];
    },

    incomeOptions() {
      return {
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              return (
                data.labels[tooltipItem.index] +
                " : " +
                data.datasets[0].data[tooltipItem.index] +
                " %"
              );
            },
          },
        },
      };
    },
  },
  methods: {
    async loadReport(timeBox) {
      this.loading = true;
      try {
        if (this.isAdmin) {
          this.totalNIncomes = await this.loadTotalNIncome(timeBox);
        }
        this.incomes = await this.loadIncome(timeBox);
      } catch (error) {
        //
      }
      this.loading = false;
    },

    async loadTotalNIncome(timeBox) {
      let resp = await this.$http.get(`Report/TotalNIncome`, {
        params: {
          fromDate: timeBox.fromDate,
          toDate: timeBox.toDate,
        },
      });
      if (resp && resp.status == 200) {
        return resp.data;
      }
      return [];
    },

    async loadIncome(timeBox) {
      let resp = await this.$http.get(`Report/Income`, {
        params: {
          fromDate: timeBox.fromDate,
          toDate: timeBox.toDate,
        },
      });
      if (resp && resp.status == 200) {
        return resp.data;
      }
      return [];
    },
  },
};
</script>
