var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{attrs:{"accent-color":"primary"}},[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cil-graph"}}),_vm._v(" Đơn hàng & Doanh số theo cửa hàng ")],1),(_vm.timeBox)?[_vm._v(" ("+_vm._s(_vm.$moment(_vm.timeBox.fromDate) .local() .format("DD/MM/YYYY"))+" - "+_vm._s(_vm.$moment(_vm.timeBox.toDate) .local() .format("DD/MM/YYYY"))+") ")]:_vm._e(),_c('div',{staticClass:"card-header-actions"},[_c('CLink',{staticClass:"card-header-action",on:{"click":function($event){_vm.showingRange = true}}},[_c('CIcon',{attrs:{"name":"cil-calendar","custom-classes":"c-icon text-dark"}})],1),_c('CLink',{staticClass:"card-header-action btn-minimize",on:{"click":function($event){_vm.isCollapsed = !_vm.isCollapsed}}},[_c('CIcon',{attrs:{"name":("cil-chevron-" + (_vm.isCollapsed ? 'bottom' : 'top'))}})],1)],1)],2),_c('CCollapse',{attrs:{"show":!_vm.isCollapsed,"duration":400}},[_c('CCardBody',[(_vm.loading)?_c('div',[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang tải... ")],1):_vm._e(),_c('p',{staticClass:"font-italic text-right"},[_c('u',[_vm._v("Ghi chú:")]),_vm._v(" "),_c('b',[_vm._v("DS")]),_vm._v(" - Doanh số. "),_c('b',[_vm._v("DT")]),_vm._v(" - Doanh thu. "),_c('b',[_vm._v("LN")]),_vm._v(" - Lợi nhuận. "),_c('b',[_vm._v("(*)")]),_vm._v(" Cùng kỳ tháng trước. ")]),_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","size":"sm","striped":"","no-sorting":"","sorter":"","sorterValue":{ column: 'orderTotal', asc: false }},scopedSlots:_vm._u([{key:"agencyId",fn:function(ref){
var index = ref.index;
return _c('td',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(index + 1)+".")])])}},{key:"orderCount",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{staticClass:"text-right"},[_c('a',{directives:[{name:"c-popover",rawName:"v-c-popover",value:({
                header: 'Số đơn hàng',
                content: _vm.popoverContent(item),
                active: false,
                focus: true,
                placement: index + 5 < _vm.tableItems.length ? 'bottom' : 'top',
              }),expression:"{\n                header: 'Số đơn hàng',\n                content: popoverContent(item),\n                active: false,\n                focus: true,\n                placement: index + 5 < tableItems.length ? 'bottom' : 'top',\n              }"}],attrs:{"href":"javascript:"}},[_vm._v(" "+_vm._s(_vm.$func.addCommas(item.orderCount))+" ")])])}},{key:"orderTotal",fn:function(ref){
              var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(item.orderTotal))+" ")])}},{key:"incomeOrder",fn:function(ref){
              var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(item.incomeOrder))+" ")])}},{key:"incomePlus",fn:function(ref){
              var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(item.incomePlus))+" ")])}},{key:"incomeTotal",fn:function(ref){
              var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(item.incomeTotal))+" ")])}},{key:"orderTotalPre",fn:function(ref){
              var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(item.orderTotalPre))+" ")])}},{key:"growRate",fn:function(ref){
              var item = ref.item;
return _c('td',{staticClass:"text-right",class:{
              'text-success': item.growRate != undefined && item.growRate > 0,
              'text-warning': item.growRate != undefined && item.growRate < 0,
              'text-danger': item.growRate != undefined && item.growRate < 0,
            }},[(item.growRate != undefined)?_c('span',[_vm._v(" "+_vm._s(_vm.$func.addCommas(item.growRate.toFixed(2)))+"% ")]):_vm._e()])}}])})],1)],1)],1),_c('date-range',{attrs:{"showing":_vm.showingRange,"timeBox":_vm.timeBox},on:{"update:showing":function($event){_vm.showingRange=$event},"update:timeBox":function($event){_vm.timeBox=$event},"update:time-box":function($event){_vm.timeBox=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }